<template>
  <div />
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import md5 from 'md5'
import LoginErrorNotification from '~/components/login/LoginErrorNotification'
import AlertBanner from '~/components/common/AlertBanner'
import { initReferrerCookie } from '~/middleware/check-referrer'

export default {
  name: 'LoginPage',
  components: {
    AlertBanner
  },
  layout: 'none',
  middleware: [initReferrerCookie],
  fetch({ redirect, store }) {
    if (store.state.auth.loggedIn) {
      return redirect(store.getters.currentSearchURL)
    }

    if (store.state.redirectToConsole) {
      return redirect(`${process.env.CONSOLE_APP_URL}/dashboard?code=nvus`)
    }
  },
  data() {
    return {
      username: '',
      password: '',
      usernameError: false,
      passwordError: false,
      notificationInstance: null,
      processingLogin: false,
      showNotMigratedError: false
    }
  },
  computed: {
    ...mapGetters(['currentSearchURL'])
  },
  beforeRouteEnter(to, from, next) {
    const shipmentAlertPath = from.fullPath.includes('/shipment-alert')
    if (shipmentAlertPath)
      window.sessionStorage.setItem('lastGuardedPath', from.fullPath)

    if (to === '/login') {
      const pathUrl = encodeURI(`${process.env.API_URL}${this.route.path}`)
      this.redirect(process.env.CONSOLE_APP_URL + `/login?returl=${pathUrl}`)
    }
    next()
  },
  mounted() {
    this.$auth.loginWith('auth0')
  },
  methods: {
    ...mapMutations(['toggleBetaModal']),

    async submit() {
      if (this.processingLogin) {
        return
      }

      this.processingLogin = true
      const errors = []

      this.usernameError = false
      this.passwordError = false

      if (this.notificationInstance) {
        this.notificationInstance.close()
      }

      if (!this.username) {
        errors.push('Username is required')
        this.usernameError = true
      }

      if (!this.password) {
        errors.push('Password is required')
        this.passwordError = true
      }

      if (errors.length) {
        this.notifyError(errors)
        setTimeout(() => {
          this.usernameError = false
          this.passwordError = false
        }, 3000)
        this.processingLogin = false
        return
      }

      try {
        await this.$auth.loginWith('local', {
          data: {
            username: this.username,
            password: this.password
          }
        })

        const isngCookie = this.$cookies.get('isng_access_login_id')

        this.$store.dispatch('setUniversalCookie', isngCookie)
        await this.$store.dispatch('nuxtServerInit')

        if (!this.$auth.loggedIn) {
          this.notifyError('An error has occurred. Please try again later.')
          this.processingLogin = false
          return
        }

        const lastGuardedPath = window.sessionStorage.getItem('lastGuardedPath')
        window.sessionStorage.removeItem('lastGuardedPath')

        this.$router.push(lastGuardedPath || this.currentSearchURL)
        this.toggleBetaModal(true)
      } catch (e) {
        if (this.notificationInstance) {
          this.notificationInstance.close()
        }

        const status = e.response ? e.response.status : 0
        switch (status) {
          case 401:
            this.usernameError = true
            this.passwordError = true
            this.notifyError('Username or Password is incorrect')

            setTimeout(() => {
              this.usernameError = false
              this.passwordError = false
            }, 3000)
            break
          case 403:
            const { reason, data: username } = e.response.data

            const redirectToNewCard = () =>
              window.location.assign(
                `https://www.importgenius.com/newcard/${md5(username)}`
              )
            const showNoAccessAlert = () => (this.showNotMigratedError = true)

            const actionMap = {
              UsCountryException: () =>
                this.notifyError('Account has no access to US dataset'),
              CancelledClientFailedToLoginException: redirectToNewCard,
              SuspendedClientFailedToLoginException: redirectToNewCard,
              InactiveClientFailedToLoginException: () =>
                this.notifyError('Account is not yet activated'),
              UserIsNotISNGClientFailedToLoginException: showNoAccessAlert
            }

            if (reason in actionMap) actionMap[reason]()
            else showNoAccessAlert()

            break
          default:
        }

        this.processingLogin = false
      }
    },

    notifyError(message) {
      message = Array.isArray(message) ? message : [message]
      const h = this.$createElement
      this.notificationInstance = this.$notify.error({
        message: h(LoginErrorNotification, {
          props: {
            errors: message
          }
        }),
        customClass: 'as-error',
        duration: 3000
      })
    },

    toggleChatWidget() {
      this.$chat.toggle()
    },
    closeAlertBanner() {
      this.showNotMigratedError = false
    }
  }
}
</script>
<style src="~/assets/scss/sections/login.scss" lang="scss"/>
